/* stylelint-disable declaration-no-important, scss/selector-no-redundant-nesting-selector */
$enable-deprecation-messages: false; /* stylelint-disable-line scss/dollar-variable-default */

@import '@coreui/coreui/scss/functions';
@import '@coreui/coreui/scss/variables';
@import '@coreui/coreui/scss/mixins';
:root {
  --light-blue: #3f70a1;
  --light-gray: #696e74;
  --light-gray-2: #adb2b9;
  --bg-active-tab: #212631;
  --tab-border-color: #323a49;
  --select-search-background: #fff !important;
}
tbody,
tr,
td {
  border-style: none !important;
}
body::-webkit-scrollbar {
  width: 0.2em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}
input[type='checkbox'] {
  cursor: pointer !important;
}
.form-switch input {
  cursor: pointer !important;
}
.border-bottom-light-blue {
  border-bottom: 1px solid #5d6065;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#gift-card-modal .modal-dialog {
  --cui-modal-width: 700px;
  width: 700px !important;
}
.modal-container {
  background-color: red;
}
.msg-date-color {
  color: #6d7b86 !important;
}
.outline-blue {
  border: 1px solid var(--blue) !important;
}
.outline-red {
  border: 1px solid var(--red) !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center !important;
}
.bg-light-blue {
  background-color: var(--light-blue) !important;
}
.bg-light-blue {
  background-color: var(--light-blue) !important;
}
.color-light-blue {
  color: var(--light-blue);
}
.color-light-gray {
  color: var(--light-gray);
}
.color-white {
  color: white !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.ml-20p {
  margin-left: 20px !important;
}
.mt-20p {
  margin-top: 20px !important;
}
.mb-20p {
  margin-bottom: 20px !important;
}
.mt-10p {
  margin-top: 10px !important;
}
.mb-10p {
  margin-bottom: 10px !important;
}
.mr-5p {
  margin-right: 5px !important;
}
.ml-5p {
  margin-left: 5px !important;
}
.mr-20p {
  margin-right: 20px !important;
}
.pt-10p {
  padding-top: 10px !important;
}
.p-10p {
  padding: 10px !important;
}
.pl-10p {
  padding-left: 10px !important;
}
.pr-10p {
  padding-right: 10px !important;
}
.card-clipped-text {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;
}
.h-200 {
  height: 200px !important;
}
.h-400 {
  height: 400px !important;
}
.h-700 {
  height: 700px !important;
}
.w-15rem {
  width: 15rem !important;
}
.w-18rem {
  width: 18rem !important;
}
.w-100-pt {
  width: 100% !important;
}
.modal-dialog {
  width: 100% !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.react-datepicker-wrapper input {
  height: 100% !important;
  border-radius: 0.375rem;
  background-color: #212631;
}
*::-webkit-scrollbar {
  width: 0.3em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.tab-container {
  border-bottom: 1px solid var(--tab-border-color);
}
.list-style-none {
  list-style-type: none;
}
.border-style-none {
  border-style: none !important;
}

.active-support-ticket-tab {
  background-color: var(--bg-active-tab);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid var(--tab-border-color);
  border-bottom: 1px solid var(--bg-active-tab);
}
.border-bottom-none {
  border-bottom: none !important;
}

.icon-hover:hover {
  // background-color: var(--bg-active-tab) !important;
  color: white !important;
  opacity: 1 !important;
}
// .pagination,
// .page-item,
// .page-link {
//   // color: white !important;
// }
.example {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .tab-content {
    background-color: var(--#{$prefix}tertiary-bg);
  }

  & + p {
    margin-top: 1.5rem;
  }
  .css-8dechd-control {
    border: 1px solid var(--tab-border-color) !important;
  }

  // Components examples
  .preview {
    + p {
      margin-top: 2rem;
    }

    > .form-control {
      + .form-control {
        margin-top: 0.5rem;
      }
    }

    > .nav + .nav,
    > .alert + .alert,
    > .navbar + .navbar,
    > .progress + .progress {
      margin-top: 1rem;
    }

    > .dropdown-menu {
      position: static;
      display: block;
    }

    > :last-child {
      margin-bottom: 0;
    }

    // Images
    > svg + svg,
    > img + img {
      margin-left: 0.5rem;
    }

    // Buttons
    > .btn,
    > .btn-group {
      margin: 0.25rem 0.125rem;
    }
    > .btn-toolbar + .btn-toolbar {
      margin-top: 0.5rem;
    }

    // List groups
    > .list-group {
      max-width: 400px;
    }

    > [class*='list-group-horizontal'] {
      max-width: 100%;
    }

    // Navbars
    .fixed-top,
    .sticky-top {
      position: static;
      margin: -1rem -1rem 1rem;
    }

    .fixed-bottom {
      position: static;
      margin: 1rem -1rem -1rem;
    }

    @include media-breakpoint-up(sm) {
      .fixed-top,
      .sticky-top {
        margin: -1.5rem -1.5rem 1rem;
      }
      .fixed-bottom {
        margin: 1rem -1.5rem -1.5rem;
      }
    }

    // Pagination
    .pagination {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .docs-example-modal {
      .modal {
        position: static;
        display: block;
      }
    }
  }
}
.color-scheme-light > * {
  background-color: #fff !important;
  color: #333 !important;
}
.color-scheme-dark > * {
  background-color: #333 !important;
  color: #fff !important;
}

// @media (prefers-color-scheme: light) {
//   .select-search-container {
//     --select-search-background: #fff !important;
//     --select-search-border: #313244;
//     --select-search-selected: #89b4fa;
//     --select-search-text: #333 !important;
//     --select-search-subtle-text: #a6adc8;
//     --select-search-highlight: #1e1e2e;
//   }
// }

// .select-search-input {
//   position: relative;
//   z-index: 1;
//   display: block;
//   height: 48px;
//   width: 100%;
//   padding: 0 40px 0 16px;
//   background: var(--select-search-background);
//   border: 2px solid var(--select-search-border);
//   color: var(--select-search-text);
//   border-radius: 3px;
//   outline: none;
//   font-family: var(--select-search-font);
//   font-size: 16px;
//   text-align: left;
//   text-overflow: ellipsis;
//   line-height: 48px;
//   letter-spacing: 0.01rem;
//   -webkit-appearance: none;
//   -webkit-font-smoothing: antialiased;
// }

.select-search-input {
  border-radius: 8px !important;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: #000000 2px 2px 2px 2px !important;
  height: 40px !important;
}

.select-search-container {
  --select-search-background: #d2d2d2;
  --select-search-border: #a7a9ae;
  --select-search-selected: gray !important;
  --select-search-text: #000;
  --select-search-subtle-text: #464855;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #abacad;
  --select-search-font: 'Inter', sans-serif;

  width: 15rem !important;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: 'Inter var', sans-serif;
  }
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
  }
}
@media (prefers-color-scheme: dark) {
  // .select-search-container {
  //   --select-search-background: red !important;
  //   --select-search-border: #313244;
  //   --select-search-selected: #89b4fa;
  //   --select-search-text: #fff !important;
  //   --select-search-subtle-text: #a6adc8;
  //   --select-search-highlight: #1e1e2e;
  // }
}
@if $enable-dark-mode {
  @include color-mode(dark) {
    .example .tab-content {
      background-color: var(--#{$prefix}secondary-bg) !important;
    }
  }
}

.table-header {
  background-color: #5856d6 !important;
  color: white !important; /* Ensure text is readable against the dark background */
}
.table-title {
  // text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 1rem 10px !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: #000000 2px 2px 2px 2px !important;
}

.custom-shadow {
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: #000000 2px 2px 2px 2px !important;
}

.table-custom-style {
  text-align: center !important;
  margin-top: 2rem !important;
}
